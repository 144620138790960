<template>
  <div
    class="modal__overlay"
    @click="showCallWaiter"
  />
  <div class="callWaiter-modal">
    <div class="container">
      <p class="callWaiter__heading">
        Вызов официанта
      </p>

      <textarea
        class="callWaiter__textarea"
        name="callWaiter"
        placeholder="Комментарий..."
      />

      <div class="callWaiter__control">
        <button
          class="callWaiter-btn send"
          @click="showCallWaiter"
        >
          Отправить
        </button>

        <button
          class="callWaiter-btn cancel"
          @click="showCallWaiter"
        >
          Отменить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Callwaiter',

  methods: {
    showCallWaiter() {
      this.$emit('showCallWaiter', false)
    },
  },
}
</script>

<style lang="scss">
.callWaiter-modal {
  position: fixed;
  bottom: 45px;

  padding-top: 16px;
  width: 100%;

  background-color: #fff;

  z-index: 2;
  border-radius: 12px 12px 0px 0px;

  & .callWaiter__heading {
    padding-bottom: 16px;
    margin-bottom: 16px;

    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    border-bottom: 1px solid #edeef1;
  }

  & .callWaiter__textarea {
    width: 100%;
    height: 200px;
    margin-bottom: 24px;

    border: none;
    outline: none;
    resize: none;

    color: #343d5d;
    font-family: 'COMMISSIONER';
    font-weight: 500;
    font-size: 16px;

    &::placeholder {
      font-family: 'Commissioner';
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  & .callWaiter__control {
    display: flex;
    justify-content: space-between;

    & .callWaiter-btn {
      min-width: 164px;
      padding: 16px 0;

      font-family: 'COMMISSIONER';
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: 0.03em;

      cursor: pointer;
      border-radius: 12px;
      border: none;
    }

    & .send {
      background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
      color: #fff;
    }

    & .cancel {
      color: #343d5d;
      background: #edeef1;
    }
  }
}
</style>
