<template>
  <div class="vDemoPage">
    <vDemoPreloader v-if="!screenSaver" />
    <vDemoOrder v-else />
  </div>
</template>

<script>
import vDemoPreloader from '@/components/demo/v-demo-preloader'
import vDemoOrder from '@/components/demo/v-demo-order'
export default {
  name: 'DemoPage',
  components: {
    vDemoPreloader,
    vDemoOrder,
  },
  data() {
    return {
      screenSaver: false,
    }
  },

  mounted() {
    this.LoadScreen()
  },

  methods: {
    LoadScreen() {
      setTimeout(() => {
        this.screenSaver = true
      }, 6000)
    },
  },
}
</script>
<style lang="scss">
.vDemoPage {
  & * {
    font-family: 'Commissioner';
  }
}
</style>
