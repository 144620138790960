<template>
  <div
    class="modal__overlay"
    @click="showChoicePayment"
  />
  <div class="choicePayment-modal">
    <div class="container">
      <button
        class="closeModal"
        @click="showChoicePayment"
      >
        <img
          src="@/assets/images/close_icon.svg"
          alt="Закрыть"
        >
      </button>

      <vGpayButton
        :gpay="false"
        @payCard="payCard"
      />

      <button
        class="main-btn applePay"
        @click="showPayment('applePay')"
      >
        <img
          src="~@/assets/images/apple_pay_logo.svg"
          alt=""
        >
      </button>

      <button
        class="main-btn googlePay"
        @click="showPayment('googlePay')"
      >
        <img
          src="~@/assets/images/google_logo.svg"
          alt=""
          class="google"
        >
        <img
          src="~@/assets/images/pay.svg"
          alt=""
        >
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import vGpayButton from '@/components/order/v-gpay-button.vue'

export default {
  name: 'Choicepayment',
  components: {
    vGpayButton,
  },
  props: {
    sum: {
      type: Number,
      required: false,
    },
    item: {
      type: Object,
      required: false,
    },
    diffCheck: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {}
  },

  methods: {
    payCard() {
      // let requestArray = {
      //   'amount':this.sum
      // };
      // let queryString = JSON.stringify(requestArray);
      // axios.post('http://kirill.test-foundarium-tapper.ru/api/order/payorder/', queryString)
      //     .then((response) => {
      //       console.log(response)
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
    },
    showChoicePayment() {
      this.$emit('showChoicePayment', false)
    },

    showPayment(val) {
      this.$emit('showPayment', true)
      this.$emit('changeMetodIcons', val)
    },
  },
}
</script>

<style lang="scss">
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: #dcdee3;
  opacity: 0.9;

  z-index: 1;
}
.choicePayment-modal {
  position: fixed;
  bottom: 45px;

  // padding-top: 45px;
  padding: 45px 16px 0 16px;
  width: 100%;

  background-color: #fff;

  z-index: 2;
  border-radius: 12px 12px 0px 0px;

  & .closeModal {
    position: absolute;
    top: 12px;
    right: 16px;

    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;
    background: rgba(219, 222, 231, 0.5);
    cursor: pointer;

    border-radius: 50%;
    border: none;
  }

  & .google {
    margin-right: 4px;
  }

  & .applePay {
    background: #fff;
    border: 1px solid #e6e7eb;

    & img {
      vertical-align: middle;
    }
  }

  & .googlePay {
    background: #241c2d;

    & img {
      vertical-align: middle;
    }
  }
}
</style>
