<template>
  <div class="menuDishesDemo">
    <div
      ref="menuDishesDemo__name"
      class="menuDishesDemo__name"
    >
      <a
        v-for="item in items"
        :key="item.id"
        v-scroll-to="{
          el: '#' + item.label,
          offset: -150,
          duration: 250,
          onStart: activeMenu,
        }"
        href="#"
        class="menuDishesDemo__search-dishes"
        :class="{ active: activeElement == item.label }"
        @click="scrollLeft"
      >
        {{ item.name }}
      </a>
    </div>
    <div
      id="hotter"
      class="menuDishesDemo__type-dishes"
    >
      <div class="menuDishesDemo__header">
        Горячее
      </div>
      <div class="menuDishesDemo__link">
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img1X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Паста балоньезе с соусом песто
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              450&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              350г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img2X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Морские гребешки с соусом
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              700&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              450г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img3X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Тыквенный суп
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              375&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              350г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img4X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Тыквенный суп с лососем и лимоном
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              650&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              350г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img5X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Стейк с клюквенным соусом
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              1000&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              650г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img6X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Бургер с беконом и яйцом пашот
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              750&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              450г
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="snacks"
      class="menuDishesDemo__type-dishes"
    >
      <div class="menuDishesDemo__header">
        Закуски
      </div>
      <div class="menuDishesDemo__link">
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img7X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Брускеты с лососем
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              300&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              150г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img8X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Сырная тарелка
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              450&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              300г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img9X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Хумус с грибами
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              450&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              250г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img10X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Овощи на гриле
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              500&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              300г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img11X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Жульен с курицей
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              550&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              250г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img12X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Киш со шпинатом
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              400&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              200г
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="desserts"
      class="menuDishesDemo__type-dishes"
    >
      <div class="menuDishesDemo__header">
        Десерты
      </div>
      <div class="menuDishesDemo__link">
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img13X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Тирамису
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              500&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              250г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img14X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Шоколадный торт
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              480&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              200г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img15X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Блинчики с ягодами
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              300&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              150г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img16X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Медовик
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              600&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              300г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img17X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Шоколадный маффин
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              250&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              150г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img18X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Миндальный круассан
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              450&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              250г
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="salads"
      class="menuDishesDemo__type-dishes"
    >
      <div class="menuDishesDemo__header">
        Салаты
      </div>
      <div class="menuDishesDemo__link">
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img19X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Капрезе
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              550&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              350г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img20X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Греческий
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              700&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              300г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img21X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Витаминный
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              500&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              250г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img22X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Цезарь с курицей
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              750&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              400г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img23X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Норвежский с лососем
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              850&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              450г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img24X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Оливье
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              450&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              300г
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="pizza"
      class="menuDishesDemo__type-dishes"
    >
      <div class="menuDishesDemo__header">
        Пицца
      </div>
      <div class="menuDishesDemo__link">
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img25X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Пицца с грушей и горнгозоллой
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              650&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              23 см
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img26X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Пицца Четыре сыра
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              500&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              23 см
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img27X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Пицца Пепперони
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              450&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              23 см
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img28X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Пицца Карбонара
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              600&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              23 см
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img29X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Пицца Цезарь
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              600&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              23 см
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img30X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Пицца Маргарита
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              450&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              23 см
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="asia"
      class="menuDishesDemo__type-dishes"
    >
      <div class="menuDishesDemo__header">
        Азия
      </div>
      <div class="menuDishesDemo__link">
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img31X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Ролл Филадельфия
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              550&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              300 г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img32X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Ролл Калифорния
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              500&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              280 г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img34X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Ролл Дабл Унаги
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              550&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              350 г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img35X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Ролл Роял Фиш
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              480&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              300 г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img36X2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Ролл Овощной
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              400&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              250 г
            </div>
          </div>
        </div>
        <div class="menuDishesDemo__cell">
          <div class="menuDishesDemo__image">
            <img
              src="~@/assets/images/menu-img-errorX2.png"
              alt=""
            >
          </div>
          <div class="menuDishesDemo__name-dish">
            Ролл с лососем
          </div>
          <div class="menuDishesDemo__priceWt">
            <div class="menuDishesDemo__price">
              350&#8381;
            </div>
            <div class="menuDishesDemo__wt">
              250 г
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scrollama from 'scrollama'

export default {
  name: 'VMenuDishesDemo',
  data() {
    return {
      activeElement: 'hotter',
      items: [
        { id: 0, name: 'Горячее', label: 'hotter' },
        { id: 1, name: 'Закуски', label: 'snacks' },
        { id: 2, name: 'Десерты', label: 'desserts' },
        { id: 3, name: 'Салаты', label: 'salads' },
        { id: 4, name: 'Пицца', label: 'pizza' },
        { id: 5, name: 'Азия', label: 'asia' },
      ],
    }
  },
  mounted() {
    scrollama()
      .setup({
        step: '.menuDishesDemo__type-dishes',
      })
      .onStepEnter((response) => {
        // console.log(response['index'])
        const links = document.querySelectorAll(
          '.menuDishesDemo__search-dishes'
        )
        for (let i = 0; i < links.length; i++) {
          links[i].classList.remove('active')
          this.$refs.menuDishesDemo__name.scrollTo(leftElem, 0)
        }
        links[response['index']].classList.add('active')
        let leftElem = links[response['index']].offsetLeft
        // let widthElem = links[response['index']].offsetWidth
        // let position = leftElem + widthElem
        this.$refs.menuDishesDemo__name.scrollTo(leftElem, 0)
        // { element, index, direction }
      })
      .onStepExit((response) => {
        // console.log(response['index'])
        // { element, index, direction }
      })
  },
  methods: {
    activeMenu(val) {
      this.activeElement = val.id
      // console.log(val)
    },
    scrollLeft(e) {
      this.$refs.menuDishesDemo__name.scrollLeft +=
        e.target.getBoundingClientRect().left -
        this.$refs.menuDishesDemo__name.getBoundingClientRect().left
    },
  },
}
</script>

<style lang="scss">
.myOrder-menu {
  position: sticky;
  top: 0;
  z-index: 1;
  //padding-top: 16px;
  padding-top: 16px;
  margin-bottom: 0 !important;
  background: white;
}
.menuDishesDemo {
  &__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 48px 0 20px 0;
    position: sticky;
    top: 40px;
    z-index: 1;
    background: white;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__search-dishes {
    font-weight: 400;
    font-size: 16px;
    color: #80869a;
    //width: 119px!important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .active {
    background: #ffffff;
    border: 1px solid #e7e8ec;
    border-radius: 11px;
    color: #241c2d !important;
    padding: 0 29px;
  }
  &__type-dishes {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 37px;
    }
  }
  &__link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__header {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }
  &__cell {
    //width: calc(50% - 6.5px);
    width: 250px;
    padding: 10px 10px 35px 10px;
    border: 1px solid #e7e8ec;
    border-radius: 12px;
    margin-bottom: 10px;
    position: relative;
  }
  &__image {
    padding-bottom: 10px;
    img {
      width: 100%;
      //object-fit: none;
    }
  }
  &__name-dish {
    font-size: 16px;
    line-height: 20px;
  }
  &__priceWt {
    display: flex;
    align-items: flex-end;
    margin-top: 5px;
    position: absolute;
    bottom: 10px;
  }
  &__price {
    font-weight: 500;
    font-size: 16px;
    margin-right: 5px;
  }
  &__wt {
    font-weight: 400;
    font-size: 12px;
    color: #80869a;
  }
}
@media screen and (max-width: 1285px) {
  .menuDishesDemo {
    &__link {
      max-width: 1000px;
      margin: 0 auto;
    }
    &__cell {
      width: 30%;
    }
  }
}
@media screen and (max-width: 650px) {
  .menuDishesDemo {
    &__link {
      max-width: 100%;
      margin: 0 0;
    }
    &__cell {
      width: calc(50% - 6.5px);
    }
  }
}
</style>
