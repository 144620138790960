<template>
  <div
    class="container"
    :class="{ 'container-menu': menu }"
  >
    <div
      class="myOrder"
      :class="{ 'myOrder-menu': menu }"
    >
      <h1 class="myOrder__heading">
        {{ !menu ? 'Мой заказ' : 'Меню' }}
      </h1>
      <!--          <span class="refresh_icon_time">{{dateUpdate}}</span><img src="@/assets/images/refresh.svg" alt="Обновить" :class="['refresh_icon ', {'loading' : startAnimation}] " @click="updateInfo()">-->
      <span class="myOrder__table">Стол 8</span>
    </div>
    <vMenuDishesDemo v-if="menu" />
    <div
      v-if="!menu"
      class="dishList"
    >
      <div
        v-if="dishItem && notPaid != 0"
        class="divide__check"
      >
        <span class="divide__check-label">Разделить счёт</span>

        <label class="divide__check-switch">
          <input
            v-model="diffCheck"
            type="checkbox"
            :disabled="notPaid == 1"
            @click="diffCheckFunc"
          >

          <span class="slider" />
        </label>
      </div>

      <div
        v-if="!dishItem"
        class="emptyList"
      >
        <img
          src="@/assets/images/clock_logo.svg"
          alt="Изображение часов"
          class="emptyList__icon"
        >

        <h3 class="emptyList__heading">
          Скоро здесь появится ваш заказ
        </h3>

        <!-- <p class="emptyList__explanation">Возможно, официант ещё не успел открыть заказ пожалуйста, подождите..</p> -->
      </div>

      <div
        v-else-if="notPaid == 0"
        class="emptyList"
      >
        <img
          src="@/assets/images/clock_logo.svg"
          alt="Изображение часов"
          class="emptyList__icon"
        >

        <h3 class="emptyList__heading">
          Заказ успешно оплачен
        </h3>

        <!-- <p class="emptyList__explanation">Возможно, официант ещё не успел открыть заказ пожалуйста, подождите..</p> -->
      </div>

      <ul
        v-else
        class="dishList__list"
      >
        <li
          v-for="item in items"
          :key="item.id"
          class="dishList__item"
          :class="{ paid: item.pay }"
        >
          <p
            v-if="!item.pay && diffCheck"
            class="dishList__item-status"
          >
            Не оплачено
          </p>
          <p
            v-else-if="!item.pay && !diffCheck"
            class="dishList__item-status"
          />
          <p
            v-else
            class="dishList__item-status"
          >
            Оплачено
          </p>

          <div class="dishList__item-dish">
            <label class="dish__checkbox">
              <input
                v-model="item.active"
                type="checkbox"
                :disabled="item.active && item.pay"
                @click="checkPosition()"
              >

              <span v-if="diffCheck" />

              <p class="dish__checkbox-text">{{ item.name }}</p>
            </label>

            <div class="dishList__item-total">
              <span class="sum">{{ item.price }} &#8381;</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="dishItem && notPaid != 0 && !menu">
      <div class="tips">
        <span class="tips__heading">Чаевые</span>

        <div class="tips__waiter">
          <img
            src="@/assets/images/image_waiter.jpg"
            alt="Фото официанта"
          >

          <div class="tips__waiter-info">
            <span>Официант</span>

            <span>Наталья</span>
          </div>
        </div>

        <div class="tips__sum">
          <!-- <span>{{ tips }}</span>  -->
          <span class="custom-input">
            <input
              v-maska="'######'"
              :value="tips"
              placeholder="0"
              @input="validTips($event.target.value)"
              @keyup="manualTips = true"
            >
            <div class="tips__sum-cursor" />
            &#8381;
          </span>

          <img
            v-if="inputTips"
            class="close-input"
            src="@/assets/images/close_icon.svg"
            alt="Закрыть"
            @click="showInputTips"
          >
          <button
            class="tips__sum-btn"
            type="reset"
            @click="resetTips"
          >
            <img
              src="@/assets/images/close_icon.svg"
              alt="Закрыть"
            >
          </button>
        </div>

        <div class="tips__percents">
          <ul class="tips__percents-list">
            <li
              class="tips__list-item"
              :class="{ active: percents == 0 && !manualTips }"
              @click="selectPercents(0)"
            >
              0%
            </li>

            <li
              class="tips__list-item"
              :class="{ active: percents == 10 && !manualTips }"
              @click="selectPercents(10)"
            >
              10%
            </li>

            <li
              class="tips__list-item"
              :class="{ active: percents == 15 && !manualTips }"
              @click="selectPercents(15)"
            >
              15%
            </li>

            <li
              class="tips__list-item"
              :class="{ active: percents == 20 && !manualTips }"
              @click="selectPercents(20)"
            >
              20%
            </li>

            <li
              class="tips__list-item"
              :class="{ active: percents == 25 && !manualTips }"
              @click="selectPercents(25)"
            >
              25%
            </li>
          </ul>
        </div>
      </div>

      <div class="check">
        <a
          href="#"
          class="check__discount"
          :class="{ active: code == '' }"
          @click.stop.prevent="showEnterPhone"
        >Применить скидку</a>

        <div
          v-if="diffCheck"
          class="check__total"
        >
          <span class="check__total-heading">Общий счёт:</span>

          <span class="check__total-sum">{{ summaAll }} &#8381;</span>
        </div>

        <div class="check__position">
          <div
            v-if="diffCheck"
            class="check__marked"
          >
            <span class="check__tips-heading">Отмеченные позиции:</span>

            <span class="check__tips-sum">{{ summaChecked }} &#8381;</span>
          </div>

          <div class="check__tips">
            <span class="check__tips-heading">Чаевые:</span>

            <span class="check__tips-sum">{{ tips }} &#8381;</span>
          </div>
          <div
            v-if="code != ''"
            class="check__tips"
          >
            <span class="check__tips-heading">Скидка 3%:</span>

            <span class="check__tips-sum">{{ discount }} &#8381;</span>
          </div>
        </div>

        <div class="check__totalPay">
          <span class="check__totalPay-heading">Итого к оплате:</span>

          <span v-if="diffCheck">{{ summaPlusDiscount }} &#8381;</span>
          <span v-if="!diffCheck">{{ summaPlusDiscount }} &#8381;</span>
        </div>
      </div>

      <form
        action=""
        class="payment"
        @submit.stop.prevent="prevent"
      >
        <button
          class="payment-btn main-btn"
          :disabled="
            (summaChecked == 0 && diffCheck) ||
              (pay && summaChecked == 0) ||
              !polityChecked
          "
          @click="showChoicePayment(true)"
        >
          Оплатить
        </button>

        <div
          class="share-btn main-btn"
          :disabled="diffCheck && summaChecked == 0"
          @click="shareLink"
        >
          Поделиться счётом
        </div>
        <label class="payment__conditions">
          <input
            v-model="checkTransaction"
            type="checkbox"
            checked
            class="payment__conditions-check"
          >
          <p class="payment__conditions-text">
            Я хочу взять на себя транзакционные издержки ({{
              transactionsSumm.toFixed(1)
            }}
            ₽), чтобы сотрудник получил полную сумму
          </p>
        </label>
        <label class="payment__conditions">
          <input
            v-model="polityChecked"
            type="checkbox"
            checked
            class="payment__conditions-check"
          >
          <div class="payment__conditions-text">
            <p>
              Согласен с условиями
              <a href="#">Пользовательского соглашения</a> и

              <a href="#">Политикой обработки персональных данных</a>
            </p>
          </div>
        </label>
      </form>
    </div>
  </div>
  <div
    v-if="!dishItem"
    class="callWaiter__text"
  >
    <span>Вызвать официанта</span>
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.001 9.99998L6.00001 15.001L0.999009 9.99998M6.00001 15L6.00001 0.999977L6.00001 15Z"
        stroke="#343D5D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div class="menu">
    <div @click="toggleMenu(true)">
      <svg
        class="menuOpen"
        :class="{ openActive: menu }"
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5 5.97571C23.8875 5.72521 25.341 5.57971 26.934 5.52571C27.786 5.49721 28.5 6.17221 28.5 7.02421V24.7797C28.5 25.5927 27.8505 26.2647 27.039 26.2767C22.401 26.3472 18.909 27.2022 15 29.5002C11.091 27.2022 7.599 26.3472 2.961 26.2782C2.1495 26.2662 1.5 25.5927 1.5 24.7797V7.02421C1.5 6.18571 2.1885 5.49721 3.0255 5.52421C7.632 5.67271 11.109 6.53971 15 8.71921"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.5 20.8572V2.51818C22.5 1.42468 21.351 0.661183 20.3745 1.15168C17.7405 2.47168 15.5205 5.15518 15 7.91818V29.5002C15.594 26.3517 18.3915 23.3067 21.4965 22.2642C22.0995 22.0617 22.5 21.4932 22.5 20.8572V20.8572Z"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div
      v-if="!dishItem"
      class="callWaiter__text"
    >
      <span>Вызвать официанта</span>
      <svg
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.001 9.99998L6.00001 15.001L0.999009 9.99998M6.00001 15L6.00001 0.999977L6.00001 15Z"
          stroke="#343D5D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <div
      class="callWaiter"
      @click="showCallWaiter(true)"
    >
      <img
        src="~@/assets/images/waiter_icon.svg"
        alt=""
      >
    </div>

    <div @click="toggleMenu(false)">
      <div>
        <svg
          class="creditCardOpen"
          :class="{ openActive: !menu }"
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.56861 15.0514L8.12615 13.4064C7.68061 11.7584 8.65956 10.0641 10.3091 9.62472L25.7921 5.49922C27.4263 5.06447 29.1221 6.04343 29.563 7.67605L32.2671 17.7108C32.7111 19.3588 31.7306 21.0531 30.0841 21.4925L26.825 22.3604"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.625 21.3366H26.825M7.70833 31.6041H23.7417C25.4452 31.6041 26.825 30.2244 26.825 28.5208V18.1346C26.825 16.4311 25.4452 15.0513 23.7417 15.0513H7.70833C6.00479 15.0513 4.625 16.4311 4.625 18.1346V28.5208C4.625 30.2244 6.00633 31.6041 7.70833 31.6041V31.6041Z"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="priceCard">
          <span
            v-if="diffCheck && !paidCheckVisible"
          >{{ summaPlusDiscount }} &#8381;</span>
          <span
            v-if="paidCheckVisible"
          >{{ dishItem ? 0 : summaPlusDiscount }} &#8381;</span>
          <span
            v-if="!diffCheck && notPaid && !paidCheckVisible"
          >{{
            !dishItem
              ? 0
              : summaPlusDiscount - transactionsSumm + transactionsSumm
          }}
            &#8381;</span>
          <span
            v-if="notPaid == 0"
          >{{ notPaid == 0 ? 0 : summaPlusDiscount }} &#8381;</span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <choicepayment
      v-if="choicePaymentVisible"
      :sum="summaPay"
      :item="items"
      :diff-check="diffCheck"
      @showChoicePayment="showChoicePayment"
      @showPayment="showPayment"
      @changeMetodIcons="changeMetodIcons"
    />
    <callwaiter
      v-if="callWaiter"
      @showCallWaiter="showCallWaiter"
    />
    <payment
      v-if="paymentVisible"
      :sum="summaPlusDiscount"
      :item="items"
      :diff-check="diffCheck"
      :payment-icons="paymentIcons"
      @showPayment="showPayment"
      @showPaidCheck="showPaidCheck"
      @returnItem="returnItem"
    />
    <sharecheck
      v-if="shareCheckVisible"
      @showShareCheck="showShareCheck"
    />
    <review
      v-if="paidCheckVisible"
      :summa-all="summaAll"
      :summa-pay="summaPay"
      :waiter="waiter"
      :order-id="orderId"
      :table-code="tableCode"
      :tips="tips"
      :summa-plus-discount="summaPlusDiscount"
      @showReview="showReview"
    />

    <!-- <review
      v-if="reviewVisible"
      @showReview="showReview"
    /> -->
    <!-- <paidcheck
      v-if="paidCheckVisible"
      @showPaidCheck="showPaidCheck"
      @showReview="showReview"
    /> -->
    <enterphone
      v-if="enterPhoneVisible"
      @showEnterPhone="showEnterPhone"
      @showEnterCode="showEnterCode"
    />
    <entercode
      v-if="enterCodeVisible"
      @showEnterCode="showEnterCode"
    />
  </div>
</template>

<script>
/* eslint-disable */
import Choicepayment from '@/components/demo/Choicepayment'
import Callwaiter from '@/components/demo/Callwaiter'
import Payment from '@/components/demo/Payment'
import Sharecheck from '@/components/demo/Sharecheck'
import Review from '@/components/demo/Review'
// import Paidcheck from "@/components/demo/PaidCheck"
import Enterphone from '@/components/demo/EnterPhone'
import Entercode from '@/components/demo/EnterCode'
import vMenuDishesDemo from '@/components/menu/v-menu-dishes-demo'
import axios from 'axios'
export default {
  name: 'Order',
  components: {
    Choicepayment,
    Callwaiter,
    Payment,
    Sharecheck,
    Review,
    // Paidcheck,
    Enterphone,
    Entercode,
    vMenuDishesDemo,
  },

  data() {
    return {
      paymentIcons: null,
      dishItem: false,
      choicePaymentVisible: false,
      callWaiter: false,
      paymentVisible: false,
      shareCheckVisible: false,
      reviewVisible: false,
      pay: false,
      percents: 10,
      summaAll: 0,
      summaChecked: 0,
      summaPay: 0,
      diffCheck: false,
      inputTips: false,
      notPaid: 0,
      manualTips: false,
      checkTransaction: true,
      polityChecked: true,
      summTransaction: 0,
      items: [
        {
          id: 0,
          name: 'Тыквенный суп',
          price: 450,
          payed: false,
          active: false,
          count: 1,
          pay: false,
          maxCount: 1,
        },
        {
          id: 0,
          name: 'Тыквенный суп',
          price: 450,
          payed: false,
          active: false,
          count: 1,
          pay: false,
          maxCount: 1,
        },
        {
          id: 1,
          name: 'Морские гребешки с соусом',
          price: 700,
          payed: false,
          active: false,
          count: 1,
          pay: false,
          maxCount: 1,
        },
        {
          id: 2,
          name: 'Паста болоньезе с соусом песто',
          price: 375,
          payed: false,
          active: false,
          count: 1,
          pay: false,
          maxCount: 1,
        },
        {
          id: 3,
          name: 'Чизкейк',
          price: 250,
          payed: false,
          active: false,
          count: 1,
          pay: false,
          maxCount: 1,
        },
        {
          id: 3,
          name: 'Чизкейк',
          price: 250,
          payed: false,
          active: false,
          count: 1,
          pay: false,
          maxCount: 1,
        },
        {
          id: 4,
          name: 'Да лука игристое розовое',
          price: 1200,
          payed: false,
          active: false,
          count: 1,
          pay: false,
          maxCount: 1,
        },
      ],
      // items : [],
      tips: 0,
      paidCheckVisible: false,
      enterPhoneVisible: false,
      enterCodeVisible: false,
      code: '',
      dateUpdate: '',
      startAnimation: false,
      subDomen: '',
      tableCode: '',
      waiter: 'Наталья',
      orderId: '',
      menu: false,
    }
  },
  computed: {
    discountFilter() {
      return 0
    },
    transactionsSumm: function () {
      let transactionTips = 0
      let transactionMenu = 0

      transactionTips = (this.tips * 5) / 100
      if (!this.diffCheck) {
        transactionMenu = (this.summaAll * 1.4) / 100
      } else {
        transactionMenu = (this.summaChecked * 1.4) / 100
      }

      this.test(transactionTips, transactionMenu)
      // this.summTransaction = transactionTips + transactionMenu;

      return transactionTips + transactionMenu
    },
    discount: function () {
      if (this.code != '') {
        if (this.diffCheck) {
          return Number(
            (
              (this.summaChecked +
                this.tips +
                (this.checkTransaction ? this.summTransaction : 0)) *
              0.03
            ).toFixed(2)
          )
        } else {
          return Number(
            (
              (this.summaAll +
                this.tips +
                (this.checkTransaction ? this.summTransaction : 0)) *
              0.03
            ).toFixed(2)
          )
        }
      } else {
        return 0
      }
    },
    summaPlusDiscount: function () {
      if (this.diffCheck) {
        return Number(
          (
            this.summaChecked +
            this.tips +
            (this.checkTransaction ? this.summTransaction : 0) +
            this.discount
          ).toFixed(0)
        )
      } else {
        return Number(
          (
            this.summaAll +
            this.tips +
            (this.checkTransaction ? this.summTransaction : 0) +
            this.discount
          ).toFixed(0)
        )
      }
    },
  },
  watch() {},

  mounted() {
    this.LoadDishList()
    this.recount()
  },

  methods: {
    toggleMenu(open) {
      this.menu = open
    },

    validTips(data) {
      console.log('TIPS', data)
      if (data.length && data > 0) {
        this.tips = parseInt(data)
      }
      if (data.length < 1 || parseInt(data) < 1) {
        this.tips = 0
      }
      console.log('data.length', data.length)
    },
    changeMetodIcons(val) {
      this.paymentIcons = val
    },

    async shareLink() {
      const shareData = {
        url: window.location.href,
      }
      try {
        await navigator.share(shareData)
        console.log('shareData success')
      } catch (err) {
        console.log('share err: ', err)
      }
    },
    test(val1, val2) {
      this.summTransaction = val1 + val2
    },
    updateInfo() {
      this.startAnimation = true
      //данные из консоли
      let uri = window.location.search.substring(1)
      let params = new URLSearchParams(uri)

      //получаем данные по get параметру id_table
      let requestArray = {
        id_table: params.get('id_table'),
      }

      let queryString = JSON.stringify(requestArray)
      axios
        .post(
          'http://kirill.test-foundarium-tapper.ru/api/order/getorderinfo/',
          queryString
        )
        .then((response) => {
          console.log(response)
          const test = response['data']['items']
          this.dateUpdate = response['data']['date']
          let tmpItems = []
          test.forEach(function (item, i) {
            var id = tmpItems.length + 1
            var found = tmpItems.some(function (el) {
              return el.id === item.id
            })
            if (!found) {
              let counter = 0
              test.forEach(function (item2, i2) {
                if (item.id == item2.id) {
                  counter++
                }
              })
              if (counter > 1) {
                tmpItems.push({
                  id: item.id,
                  name: item.name,
                  price: item.price / 100,
                  payed: false,
                  active: false,
                  count: (item.quantity / 1000) * counter,
                  pay: false,
                  maxCount: (item.quantity / 1000) * counter,
                })
              } else {
                tmpItems.push({
                  id: item.id,
                  name: item.name,
                  price: item.price / 100,
                  payed: false,
                  active: false,
                  count: item.quantity / 1000,
                  pay: false,
                  maxCount: item.quantity / 1000,
                })
              }
            }
          })
          console.log('tmpItems', tmpItems)
          this.items = tmpItems
          this.startAnimation = false
          //return false;
        })
        .catch((error) => {
          console.log(error)
        })
    },
    showChoicePayment(val) {
      this.choicePaymentVisible = val
      this.recount()
    },
    showInputTips() {
      this.inputTips = !this.inputTips
      if (this.tips == '') this.tips = 0
    },
    showCallWaiter(val) {
      this.callWaiter = val
    },

    showPayment(val) {
      this.choicePaymentVisible = false
      this.paymentVisible = val
      setTimeout(() => {
        this.paymentVisible = false
        this.paidCheckVisible = true
      }, 2000)
    },
    returnItem(val) {
      this.items = val
    },

    showShareCheck(val) {
      this.shareCheckVisible = val
    },

    selectPercents(val) {
      this.percents = val
      if (this.diffCheck) {
        this.tips = Math.round((val * this.summaChecked) / 100)
      } else {
        this.tips = Math.round((val * this.summaAll) / 100)
      }
      this.manualTips = false
    },

    showReview(val) {
      this.paidCheckVisible = false
      this.reviewVisible = val
      this.recount()
      // this.pay = true;
      // this.percents = 0;
    },

    LoadDishList() {
      setTimeout(() => {
        this.dishItem = true
        this.recount()
      }, 5000)
    },
    checkPosition() {
      setTimeout(() => {
        this.recount()
      }, 300)
    },
    plus(id) {
      this.items[id].count += 1
      this.recount()
    },
    minus(id) {
      if (this.items[id].count > 1) {
        this.items[id].count -= 1
        this.recount()
      }
    },
    diffCheckFunc() {
      this.recount()
      this.percents = 10
      this.tips = 0
      if (this.diffCheck) {
        this.tips = Number(((this.summaAll / 100) * this.percents).toFixed(0))
      } else {
        this.tips = Number(
          ((this.summaChecked / 100) * this.percents).toFixed(0)
        )
      }
      //.toFixed(0)
    },
    recount() {
      // this.resetTips();
      let summa = 0
      this.notPaid = 0
      let self = this
      this.items.forEach(function (item) {
        if (!item.pay) {
          summa = summa + item.price * item.count
          self.notPaid += 1
        }

        // this.summaAll = this.summaAll + item.price
      })

      this.summaAll = summa
      let summaChecked = 0

      let aa = this.items.filter((item) => item.active)
      aa.forEach(function (item) {
        if (!item.pay) {
          summaChecked = summaChecked + item.price * item.count
        }
      })
      this.summaChecked = summaChecked

      if (!this.diffCheck) {
        this.summaPay = this.summaAll
      } else {
        this.summaPay = this.summaChecked
      }

      if (!this.manualTips) {
        this.tips = Math.round((this.percents * this.summaPay) / 100)
      }
    },
    resetTips() {
      // if (this.diffCheck) {
      //
      // }
      this.percents = 0
      this.tips = 0
    },

    showPaidCheck(val) {
      this.paymentVisible = false
      this.paidCheckVisible = val
    },

    showEnterPhone(val) {
      if (this.code == '') {
        this.enterPhoneVisible = val
      }
    },

    showEnterCode(val) {
      this.enterPhoneVisible = false
      this.enterCodeVisible = val.showEnterCode
      this.code = val.code
    },
  },
}
</script>

<style lang="scss">
.container {
  padding: 16px 16px 50px;
}
.container-menu {
  padding: 0 16px 50px;
  position: relative;
  z-index: 2;
  background: #fff;
}
.myOrder {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  &__heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }

  &__table {
    color: #80869a;
  }
}

.dishList__list {
  & .paid {
    border: 1px solid #e6e7eb;
    color: #cccfd7;

    & .dishList__item-status {
      color: #25c26e;
    }

    & .dish__checkbox-text {
      text-decoration: line-through;

      &:before {
        border-color: #dcdee3;
      }
    }

    & .sum {
      color: #cccfd7;
      text-decoration: line-through;
    }

    & .count {
      opacity: 0.5;
    }
  }
}

.divide__check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

  border: 1px solid #e6e7eb;
  border-radius: 11px;

  background-color: #fff;

  &-label {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #494c55;
  }

  &-switch {
    position: relative;
    display: inline-block;
    min-width: 48px;
    height: 24px;

    & input {
      display: none;

      &:checked + .slider {
        background-color: #6764ff;
      }

      &:checked + .slider::before {
        transform: translateX(23px);
      }
    }
  }

  & .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6e7eb;
    transition: 0.4s;
    border-radius: 50px;

    &::before {
      position: absolute;
      content: '';
      width: 19px;
      height: 19px;
      left: 3px;
      bottom: 3px;
      background-color: #fff;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

.dishList__item {
  margin-top: 10px;
  padding: 12px;

  border: 1px solid #e6e7eb;
  border-radius: 12px;
  list-style-type: none;

  background-color: #fff;

  &.paid {
    & .dishList__item-dish {
      & .dish__checkbox {
        & input:checked + span {
          background-image: url('~@/assets/images/checked_paid.svg');
        }
      }
    }
  }

  &-status {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;

    color: #80869a;
  }

  &-dish {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .dish__checkbox {
      display: flex;
      align-items: center;

      & span {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1.5px solid #dcdee3;
        border-radius: 0.25em;
        margin-right: 21px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px;
      }

      & input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked + span {
          background-image: url('~@/assets/images/checked.65527f52.svg');
        }
      }

      &-text {
        display: inline-flex;
        align-items: center;
        max-width: 170px;

        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &-total {
    display: flex;
    flex-direction: column;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: right;

    color: #413dff;

    //& .count {
    //    display: flex;
    //    align-items: center;
    //    margin-top: 8px;
    //    padding: 3px 7px;
    //
    //    border: 1px solid #EDEEF1;
    //    border-radius: 6px;
    //    background: #F7F8FA;
    //
    //    font-weight: normal;
    //    font-size: 13px;
    //    line-height: 16px;
    //
    //    &__quantity {
    //        font-size: 13px;
    //        line-height: 18px;
    //
    //        color: #80869A;
    //    }
    //
    //    &__btn {
    //        padding: 0;
    //
    //        outline: none;
    //        border: none;
    //        background-color: inherit;
    //        cursor: pointer;
    //
    //        & svg {
    //            vertical-align: middle;
    //        }
    //    }
    //
    //    & span {
    //        margin: 0 11px;
    //    }
    //}
  }
}

.emptyList {
  margin-top: 130px;
  margin-bottom: 31px;
  padding: 17px 0;

  text-align: center;

  &__icon {
    margin-bottom: 18px;
  }

  &__heading {
    margin: 0 auto;
    margin-bottom: 16px;
    max-width: 298px;

    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }

  // &__explanation {
  //     margin: 0 auto;
  //     max-width: 279px;

  //     font-weight: 500;
  //     font-size: 16px;
  //     line-height: 20px;

  //     color: #343D5D;
  // }
}

.tips {
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 11px;

  border: 1px solid #e6e7eb;
  border-radius: 11px;

  background-color: #fff;

  &__heading {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
  }

  &__waiter {
    margin-top: 16px;
    margin-bottom: 13px;

    & img {
      margin-right: 12px;
    }

    &-info {
      display: inline-flex;
      flex-direction: column;
      vertical-align: top;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #343d5d;

      & span:first-child {
        color: #80869a;
      }
    }
  }

  &__sum {
    position: relative;

    padding-bottom: 41px;

    font-weight: 600;
    font-size: 17px;
    line-height: 22px;

    text-align: center;

    border-bottom: 1px solid #edeef1;

    & .custom-input {
      display: flex;
      align-items: center;
      position: absolute;
      left: calc(50% - 35px);

      transform: translateX(-50%);

      & input {
        width: 40px;
        padding: 0;
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        width: 100px;
        border: none;
        text-align: end;
        margin-right: 2px;
      }
    }

    & .close-input {
      position: absolute;
      right: 0;
      z-index: 2;
    }

    &-btn {
      position: absolute;
      right: 0;
      top: 0;

      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      outline: none;
      background: #f7f8fa;
      cursor: pointer;

      border-radius: 50%;
      border: 1px solid #edeef1;
    }
  }

  &__percents {
    padding-top: 18px;

    &-list {
      display: flex;
      justify-content: space-between;
      list-style-type: none;

      & .tips__list-item {
        padding: 4px;

        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #80869a;
        cursor: pointer;

        background: #f7f8fa;
        border-radius: 6px;
        border: 1px solid #f7f8fa;

        &:hover {
          color: #010d35;
          border: 1px solid #6764ff;
        }
      }

      & .active {
        color: #010d35;
        border: 1px solid #6764ff;
      }
    }
  }
}

.check {
  margin-bottom: 24px;
  padding: 12px;

  border: 1px solid #dbdee7;
  border-radius: 11px;

  background-color: #fff;

  &__discount {
    width: 155px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px auto;
    text-align: center;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #9796b1;
    text-decoration: none;
    &.active {
      color: #6764ff;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;

    font-weight: 600;
    font-size: 17px;
    line-height: 22px;

    border-bottom: 1px solid #edeef1;
  }

  &__position {
    padding-bottom: 16px;
    padding-top: 8px;

    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    border-bottom: 1px solid #edeef1;

    & .check__tips {
      display: flex;
      justify-content: space-between;

      color: #343d5d;
    }

    & .check__marked {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
    }
  }

  &__totalPay {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;

    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
}

.payment {
  margin-bottom: 49px;

  & .payment-btn:disabled {
    color: #020000;
    letter-spacing: 0.03em;
    background: #edeef1;
    cursor: no-drop;
  }

  & .share-btn {
    color: #343d5d;
    background: #edeef1;
  }

  &__conditions {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #80869a;

    &-check {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked + .payment__conditions-text::before {
        background-image: url('~@/assets/images/paid_check.d789c5ba.svg');
      }
    }

    &-text {
      display: inline-flex;
      align-items: center;
      user-select: none;

      &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1.5px solid #dcdee3;
        border-radius: 0.4em;
        margin-right: 17px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px;
      }

      & a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #80869a;
      }
    }
  }
}
.callWaiter__text {
  left: 50%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  transform: translateX(-50%);
  position: fixed;
  bottom: 110px;
  z-index: 1;
  & span {
    margin-bottom: 13px;
  }
}

.menu {
  position: fixed;
  bottom: 0;

  width: 100%;
  padding-bottom: 62px;

  border-top: 1px solid #cccfd7;
  background-color: #fff;

  z-index: 3;

  & .callWaiter {
    position: absolute;
    left: 50%;
    top: -50%;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    border-radius: 100px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #cccfd7;
    border-right: 1px solid #cccfd7;
    background-color: #fff;
    transform: translateX(-50%) rotate(45deg);

    & img {
      transform: rotate(-45deg);
    }
  }

  & .menuOpen {
    position: absolute;
    top: 16px;
    left: 65px;
    stroke: #cccfd7;
  }
  & .creditCardOpen {
    position: absolute;
    top: 14px;
    right: 65px;
    stroke: #cccfd7;
  }
  .openActive {
    stroke: #343d5d;
  }
  .priceCard {
    position: absolute;
    top: 5px;
    right: 35px;
    border: 1px solid #343d5d;
    background: #343d5d;
    color: #fff;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 500;
    padding: 3px 6px;
  }
}

.main-btn {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 12px;

  font-family: 'Commissioner';
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #fff;

  border-radius: 12px;
  border: none;

  outline: none;
  cursor: pointer;

  background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
}
.main-btn:disabled {
  color: #000000;
  background: #edeef1;
}
.refresh_icon {
  width: 30px;
  height: 30px;
}
.refresh_icon_time {
  font-weight: 100;
  color: #c8c9cc;
}
.refresh_icon.loading {
  animation: loadingTime 1s linear infinite;
}

@keyframes loadingTime {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.callWaiter__text {
  left: 50%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  transform: translateX(-50%);
  position: fixed;
  bottom: 110px;
  z-index: 1;
  & span {
    margin-bottom: 13px;
  }
}
</style>
